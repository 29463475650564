
















































import { Component, Vue, Prop } from "vue-property-decorator";
import XingzhuangYanse from "./components/xingzhuang-yanse.vue";
import Yanse from "./components/yanse.vue";
import Wenli from "./components/wenli.vue";
@Component({
  components: {
    XingzhuangYanse,
    Yanse,
    Wenli,
  },
})
export default class Name extends Vue {
  @Prop()
  private r: any;
  private cState: any = "形状颜色";
  /**
   * @description 改变显示部分
   */
  private changeState(state: any) {
    this.cState = state;
  }
  private previewImg(src: any) {
    window.open(src);
  }
}
