









































































































import { Component, Vue, Prop } from "vue-property-decorator";
import * as _ from "lodash";
@Component({})
export default class TongueWenli extends Vue {
  @Prop()
  private r: any;
  @Prop()
  private origin: any;
}
