
























































































import { Component, Vue, Prop } from "vue-property-decorator";
@Component({})
export default class TongueYanse extends Vue {
  @Prop()
  private r: any;
  @Prop()
  private origin: any;
  private get thead() {
    const arr = JSON.parse(JSON.stringify(this.r));
    return arr[0];
  }
  private get tbody() {
    const arr = JSON.parse(JSON.stringify(this.r));
    return arr.slice(1);
  }
}
