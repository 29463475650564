











































import { Component, Vue, Prop } from "vue-property-decorator";
import { GetTonguePreview } from "@/request/research";
import TongueReport from "@/components/tongue/tongue-report/tongue-report.vue";
@Component({
  components: { TongueReport },
})
export default class Name extends Vue {
  private tongueId: any = "";
  private r: any = {
    舌象照片: {},
  };
  /**
   * @description 获取数据
   */
  private fetchResult() {
    const params: any = {
      params: {
        tongue_id: this.tongueId,
      },
    };
    GetTonguePreview(this, params).then((data: any) => {
      this.r = data;
    });
  }
  /**
   * @description 初始化
   */
  private mounted() {
    if (!this.$route.query.tongue_id) {
      this.$message.error("参数有误");
      this.$router.back();
      return;
    }
    this.tongueId = this.$route.query.tongue_id;
    this.fetchResult();
  }
}
