var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"xingzhuang"},_vm._l((_vm.tree),function(items,index){return _c('div',{key:index,staticClass:"wrap"},[_c('div',{staticClass:"left"},[_vm._v(_vm._s(items.title))]),_c('div',{staticClass:"right"},_vm._l((items.val),function(item,idx){return _c('div',{key:idx},[(idx === 0)?_c('div',{staticClass:"title noBorder"},[_c('div',{staticClass:"myL"}),_c('div',{staticClass:"myR"},[_vm._v(_vm._s(item.title))])]):_vm._e(),(idx !== 0)?_c('div',{staticClass:"title"},[_c('div',{staticClass:"myL"}),_c('div',{staticClass:"myR"},[_vm._v(_vm._s(item.title))])]):_vm._e(),_vm._l((item.val),function(opt,i){return _c('div',{key:i,staticClass:"part"},[_c('div',{staticClass:"leftPart"},[_vm._v(_vm._s(opt))]),(
              _vm.r[items.title] &&
              _vm.r[items.title][item.title] &&
              _vm.r[items.title][item.title][opt]
            )?_c('div',{staticClass:"rightPart"},[(opt !== '位置')?_c('div',[(
                  opt.indexOf('占舌比') !== -1 &&
                  _vm.r[items.title][item.title][opt]
                )?_c('span',[_vm._v(_vm._s((_vm.r[items.title][item.title][opt] * 100).toFixed(2))+"%")]):_c('span',[_vm._v(_vm._s(_vm.r[items.title][item.title][opt]))])]):_vm._e(),(opt === '位置')?_c('div',_vm._l((_vm.r[items.title][item.title][opt]),function(item,index){return _c('span',{key:index},[(index > 0)?_c('i',[_vm._v("、")]):_vm._e(),_vm._v(" "+_vm._s(item)+" ")])}),0):_vm._e()]):_vm._e()])})],2)}),0)])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }